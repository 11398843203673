<script lang="ts">
  import { parse, stringify } from "query-string";

  import * as getBrowser from "get-browser";
  const { isSafari } = getBrowser.default;

  const parsedSearch = parse(location.search);

  const prefilled = !!parsedSearch["table"];
  let table = parsedSearch["table"] || "";
  const store = parsedSearch["loja"] || "";

  const playStoreLink =  "https://play.google.com/store/apps/details?id=com.cubostecnologia.zigcliente";
  const appleStoreLink = "https://apps.apple.com/us/app/zig/id1154643255";
  let eatizLink = "";

  const isApple = isSafari();
  const storeLink =  isApple? appleStoreLink : playStoreLink;

  function updateTable (){
      const tableParsed = "?"+stringify({ table:table });
      eatizLink = store ? `https://${store}.namesa.online/${tableParsed}` : "";
      console.log(eatizLink);
  }
  updateTable();

</script>

<style lang="scss">
  // font
  @import url("https://fonts.googleapis.com/css2?family=Ubuntu:wght@400;500;700&display=swap");

  @function unit($count) {
    @return 4px * $count;
  }

  @function half($count) {
    @return 2px * $count;
  }

  @mixin Ubuntu {
    font-family: Ubuntu;
    font-style: normal;
  }
  @mixin fix-size {
  }

  // variables
  $white: #fff;
  $almostWhite: #f1f3f5;
  $lightGrey: darkgrey;
  $middleGrey: grey;
  $darkerGrey: #666666;
  $almostBlack: #222220;
  $blue: #3fabd9;

  $widthLimit: 375px;
  $headerHeight: unit(50);
  $contentSize: unit(125);

  :global(body),
  :global(html) {
    all: unset;
    margin: 0;
    padding: 0;
    background: $white;
    font-family: "Ubuntu", sans-serif;
  }

  .mainWrapper {
    background: $white;
    height: 100vh;

    .header {
      box-sizing: border-box;
      height: $headerHeight;
      min-height: $headerHeight;
      max-height: $headerHeight;
      display: flex;
      justify-content: center;

      padding: unit(4);
      background: linear-gradient(  rgba(0, 0, 0, 0.4),  rgba(0, 0, 0, 0.7) ), url("/public/assets/compressed.jpg");
      background-size: cover;
      background-repeat: no-repeat;
      background-position: center;


      box-shadow: unit(0) unit(0) unit(5) unit(1) rgba(0, 0, 0, 0.099104);

      .widthLimiter {
        box-sizing: border-box;
        height: 100%;
        width: 100%;
        max-width: $widthLimit;

        display: flex;
        justify-content: center;
        align-items: flex-start;
        flex-direction: column;

        .title {
          @include Ubuntu;
          font-weight: bold;
          font-size: unit(6);
          line-height: unit(7);
          text-align: center;
          color: $white;
          margin-bottom: unit(6);
          margin-top: unit(3);
        }

        .subTitle {
          @include Ubuntu;
          font-weight: 500;
          font-size: unit(4);
          line-height: unit(5);
          color: $white;
        }
      }
    }

    .content {
      display: flex;
      justify-content: center;
      box-sizing: border-box;
      height: 100%;
      width: 100%;
      padding: unit(4);
      max-height: calc(100vh - #{$headerHeight});
      // background-color: red;
      // @media (min-height: $headerHeight +  $contentSize) {
      //   max-height: $contentSize;
      // }

      .widthLimiter {
        width: 100%;
        height: 100%;
        max-width: $widthLimit;
        box-sizing: border-box;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        align-items: center;

        .center {
          box-sizing: border-box;
          width: 100%;
          .label {
            @include Ubuntu;
            font-weight: bold;
            font-size: unit(6);
            line-height: unit(7);
            text-align: center;
            color: $almostBlack;
            margin-bottom: unit(6);
          }
          input {
            all: unset;
            background: $almostWhite;
            box-sizing: border-box;
            border-radius: unit(2);
            border: half(1) solid $almostWhite;
            height: unit(24);
            width: 100%;
            transition: all 0.2s ease-in-out;

            padding: unit(5) unit(0) unit(5) unit(5);

            @include Ubuntu;
            font-weight: 500;
            font-size: unit(14);
            letter-spacing: unit(5);
            line-height: 62px;
            text-align: center;
            color: $darkerGrey;
            margin-bottom: unit(5);
            &::placeholder {
              color:rgba(0, 0, 0, 0.10);
            }

            &:hover {
              border-color: $lightGrey;
            }
            &:focus {
              border-color: $middleGrey;
            }
          }
        }

        .footer {
          box-sizing: border-box;
          width: 100%;

          width: 100%;
          height: 100%;
          display: flex;
          justify-content: flex-end;
          flex-direction: column;

          button {
            cursor: pointer;
            border-radius: unit(2);
            width: 100%;
            height: unit(15);
            outline: none;

            @include Ubuntu;
            font-weight: bold;
            font-size: unit(4);
            line-height: unit(5);
            text-align: center;
            border: half(1) solid;
            transition: all 0.2s ease-in-out;
            box-shadow: none;

            &:disabled{
              opacity: 0.5;
            }
          }

          button:first-of-type {
            margin-bottom: unit(4);
          }

          button.appButton {
            background: $blue;
            border-color: $blue;
            margin-bottom: 50px;
            &:hover {
                &:not(:disabled){
                    box-shadow: unit(0) unit(0) unit(5) unit(1) rgba(63, 171, 217, 0.3);
                }
            }
            color: $almostBlack;
          }
          button.siteButton {
            background: $white;
            color: $lightGrey;
            border: none;
            &:hover {
               &:not(:disabled){
                box-shadow: unit(0) unit(0) unit(5) unit(1) rgba(0, 0, 0, 0.099104);
               }
            }
          }
        }
      }
    }
  }
</style>

<div class="mainWrapper">
  <div class="header">
    <div class="widthLimiter">
      <div class="title">Bem vindo</div>
      <div class="subTitle">
        Informe sua mesa para podermos te atender da melhor forma
      </div>
    </div>
  </div>
  <div class="content">
    <div class="widthLimiter">

      <div class="center">
        <div class="label">O numero de sua mesa é</div>
        <input bind:value={table} on:input="{() => updateTable()}" disabled={prefilled} placeholder="000" />
      </div>
      <div class="footer">
        <a href={storeLink} >
          <button class="appButton">
            Continuar pelo app
          </button>
        </a>
        {#if !!eatizLink}
          <a href={!!table? eatizLink: ""}  >
            <button  disabled={!table} class="siteButton">Continuar pelo site</button>
          </a>
        {/if}

      </div>
    </div>
  </div>
</div>
